import "../scss/global.scss";
import Glide from '@glidejs/glide';
import SmoothScroll from 'smooth-scroll';

import Menu from "./components/Menu";
import Popup from "./components/Popup";
import FormContacto from "./components/FormContacto";
import { WidowUnmaker } from './components/WidowUnmaker.js'
import eqheights from 'eqheights';

window.menu = new Menu();

// widow unmaker. Ignores forced line breaks and hard coded nowrpas
let wunmaker = new WidowUnmaker("p, h2, h3, h4, .lead, .small, strong", "br, span[syle='white-space: nowrap;']", ".hero h1, .hero h1 strong");

let slider = document.querySelector(".glide");
if(slider){
    new Glide('.glide', {
        type: 'carousel',
        gap: 0,
        autoplay: 5000,
        hoverpause: false
    }).mount()
}

if(document.querySelector("form")){
    let form = new FormContacto(document.querySelector("form"));
}

const pop = new Popup();

var scroll = new SmoothScroll('a[href*="#"]', {
    header: 'header.header', // Selector for fixed headers (must be a valid CSS selector)
    topOnEmptyHash: true, // Scroll to the top of the page for links with href="#"
    // Speed & Duration
    speed: 500, // Integer. Amount of time in milliseconds it should take to scroll 1000px
    clip: true, // If true, adjust scroll distance to prevent abrupt stops near the bottom of the page
    easing: 'easeInOutCubic',
    updateURL: true,
    popstate: true,
});

eqheights('.eqHeightFeatures');