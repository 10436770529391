import gsap from "gsap";
import { storesingleton } from "../Storesingleton.js";

class Menu {

	constructor(){
		this.header = document.getElementsByTagName("header")[0];

		// fixed thereshold for hiding the menu. Before this scroll pos the menu is always visible
		this.hideLimit = 120;
		// fixed thereshold for addign transparent class
		this.transparentLimit = window.innerHeight / 6;
		// holds menu state
		this.menuShown = true;
		// holds mobile menu state
		this.mobileOpen = false;

		//events handling
		this.openMobileBtn = document.getElementById("header_hamburger");
		this.closeMobileBtn = document.getElementById("header_close");
		this.headerBlock = document.getElementById("header_block");
		this.menu = document.getElementById("mainmenu");

		//scope enclosure patern for buttons
		this.openMobileBtn.addEventListener("click", e => {
			this.openMobile();
			e.preventDefault();
		});
		this.closeMobileBtn.addEventListener("click", e => {
			this.closeMobile();
			e.preventDefault();
		});
		this.headerBlock.addEventListener("click", e => {
			this.closeMobile();
			e.preventDefault();
		});
		this.menu.addEventListener("click", e => {
			if(e.target.tagName === 'A'){
				this.closeMobile();
			}
		});

		//scroll monitoring var
		this.last_monitored_pos = 0;
		this.direction = null;
		//ticker to monitor scrolling
		this.last_known_scroll_position = 0;
		this.ticking = false;
		window.addEventListener( "scroll", (e) => {
			this.last_known_scroll_position = (window.scrollY | window.pageYOffset);

			if (!this.ticking) {
				window.requestAnimationFrame(function() {
					this.checkMenuStatus(this.last_known_scroll_position);
					this.ticking = false;
				}.bind(this));

				this.ticking = true;
			}
		});
		window.dispatchEvent(new CustomEvent('scroll'));

		window.addEventListener( "resize", (e) => {
			this.transparentLimit = window.innerHeight / 6;
		});
		//bind to singleton
		storesingleton.closeMobileMenu = this.closeMobile.bind(this);
	}

	checkMenuStatus(newpos){
		//show hide
		let direction = null;
		if(this.last_monitored_pos > newpos) direction = 1;
		if(this.last_monitored_pos < newpos) direction = -1;
		if(this.last_monitored_pos == newpos) direction = null;
		this.last_monitored_pos = newpos;
		
		//show hide
		if(direction == 1 || newpos < this.hideLimit){
			this.toggleScrollShow();
		}else if (direction == -1 && newpos > this.hideLimit && window.innerHeight<600){
			this.toggleScrollHide();
		}

		//trans opaque
		if(!this.header.classList.contains("header--top") && newpos < this.transparentLimit){
			this.header.classList.add("header--top")
		}else if(this.header.classList.contains("header--top") && newpos > this.transparentLimit){
			this.header.classList.remove("header--top")
		}
		this.direction = direction;
	}

	toggleScrollHide(){
		if(!this.mobileOpen){
			gsap.to(this.header,{y:"-100%", duration: 0.3});
			this.menuShown = false;
		}
	}

	toggleScrollShow(){
		gsap.to(this.header, {y:"0%", duration: 0.3});
		this.menuShown = true;
	}

	openMobile(){
		this.header.classList.add("mobileOpen")
		this.mobileOpen = true;
	}

	closeMobile(){
		this.header.classList.remove("mobileOpen")
		this.mobileOpen = false;
	}
}

export default Menu;